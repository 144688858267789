import Footer from '@/src/components/footer/footer';
import Header from '@/src/components/header/header';
import IconInput from '@/src/components/icon-input/icon-input';
import Navbar from '@/src/components/navbar/navbar';
import { TranslationProvider } from '@/src/contexts/translation.context';
import { TranslationService } from '@/src/services/translation.service';
import { graphql, PageProps } from 'gatsby';
import React, { Fragment, useEffect, useState } from 'react';
import SearchIcon from '@/assets/icons/input-icons/search.inline.svg';
import * as styles from './activities.module.scss';
import { Badge } from '@/src/components/badge/badge';
import { BiFilterAlt, BiMap } from 'react-icons/bi';
import ActivityListItem from './activity-list-item';
import { ActivityModel } from '@/src/models/activity.model';
import GoogleMapReact from 'google-map-react';
import MarkerBlack from '@/assets/icons/markers/marker-black.inline.svg';
import MarkerNorth from '@/assets/icons/markers/marker-north.inline.svg';
import MarkerEast from '@/assets/icons/markers/marker-east.inline.svg';
import MarkerSouth from '@/assets/icons/markers/marker-south.inline.svg';
import MarkerWest from '@/assets/icons/markers/marker-west.inline.svg';
import BusNorth from '@/assets/icons/markers/bus-north.svg';
import BusEast from '@/assets/icons/markers/bus-east.svg';
import BusSouth from '@/assets/icons/markers/bus-south.svg';
import BusWest from '@/assets/icons/markers/bus-west.svg';
import useActivitySearch from '@/src/hooks/activity-search.hook';
import BottomSheet from '@/src/components/bottom-sheet/bottom-sheet';
import ActivitiesFilter, { ActivitiesFilterResult } from './activities-filter/activities-filter';
import { CategoryModel, SubCategoryModel } from '@/src/models/category.model';
import useActivityFilter, { getGlobalFilterResult, setGlobalFilterResult } from '@/src/hooks/activity-filter.hook';
import ActivityMapItem from './activity-map-item';
import mapStyle from '@/assets/map.json';
import SignupFunnel from '@/src/components/signup-funnel/signup-funnel';
import firebase from 'gatsby-plugin-firebase';
import { Helmet } from 'react-helmet';

type SubPage = 'list' | 'map';

type ActivitySubPageProps = {
  translation: TranslationService;
  onPageToggle: () => void;
  data: ActivityQueryResult;
  defaultActivityFocus?: string;
};

type ActivityQueryResult = {
  allActivity: {
    edges: {
      node: ActivityModel;
    }[];
  };
  allCategory: {
    edges: {
      node: CategoryModel;
    }[];
  };
  allSubCategory: {
    edges: {
      node: SubCategoryModel;
    }[];
  };
};

function ListView({ translation, onPageToggle, data }: ActivitySubPageProps) {
  const [query, setQuery] = useState('');
  const [filterState, setFilterState] = useState(false);
  const searchedActivities = useActivitySearch(data.allActivity, query, translation);
  const [filterResult, setFilterResult] = useState(getGlobalFilterResult());
  const filteredActivities = useActivityFilter(filterResult, searchedActivities);
  const [funnelOpen, setFunnelOpen] = useState(false);
  const [matchHeight, setMatchHeight] = useState(null);

  useEffect(() => {
    (async () => {
      if(!matchHeight) {
        const MatchHeight = (await import('matchheight')).default;
        setMatchHeight(MatchHeight);
      }
    })();
  }, []);

  useEffect(() => {
    if(matchHeight) {
      setTimeout(() => {
        matchHeight.init();
        matchHeight.update();
      }, 100);
    }
  }, [matchHeight, filteredActivities]);

  const openFilter = () => {
    setFilterState(!filterState);
  };

  const onFilterResult = (result: ActivitiesFilterResult) => {
    setGlobalFilterResult(result);
    setFilterResult(result);
  };

  return (
    <div className="scrollable">
      <div className="app__wrapper">
        <div className={styles.activities__filter}>

          <h1 className={styles.heading}>{translation.translate('activities.heading')}</h1>

          <IconInput
            className={styles.inputField}
            placeholder={translation.translate('activities.search_placeholder')}
            value={query}
            onChange={event => setQuery(event.target.value)}
            icon={<div><SearchIcon style={{ width: '1rem', height: '1rem' }} /></div>}></IconInput>

          <div className={styles.badges}>
            <Badge.Filled
              onClick={openFilter}
              text={`${translation.translate('activities.filter')}${(filterResult || {}).numFilters > 0 ? ` (${filterResult.numFilters})` : ''}`}
              icon={<BiFilterAlt />}></Badge.Filled>
            <Badge.Outlined onClick={onPageToggle} text={translation.translate('activities.show_on_map')} icon={<BiMap />}></Badge.Outlined>
          </div>
        </div>
      </div>

      <div className={styles.activityList}>
        {filteredActivities().filter((edge) => {
          // Skip activity if not published
          if(!edge.node.published) {
            return false;
          }

          return true;
        }).map((edge, index) => {
          const heading = edge.node.title[translation.lang];
          const free = edge.node.free;
          const location = edge.node.location;
          const imageUrl = edge.node.images[0] ? `uploads/${edge.node.id}/image/${edge.node.images[0].id}` : null;

          // Change subheading and description if the activity is free or not 
          let subheading = null;
          let description = null;
          if(free) {
            subheading = translation.translate('activities.free_activity');
          } else if(edge.node.offers.length > 0) {
            const offers = edge.node.offers;

            if(offers.length > 0) {
              subheading = offers[0].title[translation.lang];

              if(offers[0].amount.length > 0) {
                description = offers[0].amount;
              } else {
                let percentageOfferSuffix = translation.translate('activities.percentage_offer_suffix');
                description = '-' + offers[0].percentage + '% ' + percentageOfferSuffix;
              }
            }
          }

          const href = `/${translation.lang}/activities/${edge.node.company.slug}/${edge.node.slug[translation.lang]}`;

          return <ActivityListItem
            key={edge.node.id}
            translation={translation}
            heading={heading}
            subheading={subheading}
            description={description}
            hasImage={!!imageUrl}
            imageUrl={imageUrl}
            locations={location}
            href={href}
            activityId={edge.node.id}
            styling="list"
            onFailedFunnelTest={() => setFunnelOpen(true)}></ActivityListItem>;
        })}
      </div>
      <Footer></Footer>
      <BottomSheet open={filterState}>
        <ActivitiesFilter
          initialResult={filterResult}
          categories={data.allCategory}
          subCategories={data.allSubCategory}
          onClose={() => setFilterState(false)}
          onResult={onFilterResult}></ActivitiesFilter>
      </BottomSheet>
      <BottomSheet open={funnelOpen}>
        <SignupFunnel
          onClose={() => setFunnelOpen(false)}
          actionKey="favourite_activity"></SignupFunnel>
      </BottomSheet>
    </div>
  );
}

type SelectedActivityProps = {
  selectedActivity: ActivityModel,
  translation: TranslationService,
  showSelectedActivity: boolean,
  setFunnelOpen: (v: boolean) => any,
};

function SelectedActivity({ selectedActivity, translation, showSelectedActivity, setFunnelOpen }: SelectedActivityProps) {
  let heading: string;
  let subheading: string;
  let description: string;
  let imageUrl: string = null;
  let location: any = [];
  let activityId: string = '';
  let href: string = '/';

  if(selectedActivity) {
    heading = selectedActivity.title[translation.lang];
    const free = selectedActivity.free;
    imageUrl = selectedActivity.images[0] ? `uploads/${selectedActivity.id}/image/${selectedActivity.images[0].id}` : null;
    location = selectedActivity.location;
    activityId = selectedActivity.id;
    href = `/${translation.lang}/activities/${selectedActivity.company.slug}/${selectedActivity.slug[translation.lang]}`;

    subheading = null;
    description = null;
    if(free) {
      subheading = translation.translate('activities.free_activity');
    } else {
      const offers = selectedActivity.offers;

      if(offers.length > 0) {
        subheading = offers[0].title[translation.lang];

        if(offers[0].amount.length > 0) {
          description = offers[0].amount;
        } else {
          let percentageOfferSuffix = translation.translate('activities.percentage_offer_suffix');
          description = '-' + offers[0].percentage + '% ' + percentageOfferSuffix;
        }
      }
    }
  }

  return (
    <ActivityMapItem
      open={showSelectedActivity}
      heading={heading}
      subheading={subheading}
      description={description}
      hasImage={imageUrl != null}
      imageUrl={imageUrl}
      locations={location}
      href={href}
      activityId={activityId}
      onFailedFunnelTest={() => setFunnelOpen(true)}></ActivityMapItem>
  );
}

function MapView({ translation, onPageToggle, data, defaultActivityFocus }: ActivitySubPageProps) {
  const [markers, setMarkers] = useState([]);
  const [query, setQuery] = useState('');
  const [filterState, setFilterState] = useState(false);
  const searchedActivities = useActivitySearch(data.allActivity, query, translation);
  const [filterResult, setFilterResult] = useState(getGlobalFilterResult());
  const filteredActivities = useActivityFilter(filterResult, searchedActivities);
  const [selectedActivity, setSelectedActivity] = useState(null as ActivityModel);
  const [showSelectedActivity, setShowSelectedActivity] = useState(false);
  const [funnelOpen, setFunnelOpen] = useState(false);
  const [selectedActivityId, setSelectedActivityId] = useState('');

  const openFilter = () => {
    setFilterState(!filterState);
  };

  const onFilterResult = (result: ActivitiesFilterResult) => {
    setGlobalFilterResult(result);
    setFilterResult(result);
  };

  useEffect(() => {
    if(defaultActivityFocus) {
      const activity = data.allActivity.edges.find(a => a.node.id === defaultActivityFocus);
      if(activity) {
        setSelectedActivity(activity.node);
        setShowSelectedActivity(true);
        setSelectedActivityId(activity.node.id);
      }
    }
  }, []);

  const onMarkerClicked = (activity: ActivityModel) => {
    setSelectedActivityId('');

    if(selectedActivity) {
      if(selectedActivity.id === activity.id) {
        setTimeout(() => setSelectedActivity(null), 200); // Wait for exit animation to finish
        setShowSelectedActivity(false);
      } else {
        setSelectedActivity(activity);
        setShowSelectedActivity(true);
        setSelectedActivityId(activity.id);
      }
    } else {
      setSelectedActivity(activity);
      setShowSelectedActivity(true);
      setSelectedActivityId(activity.id);
    }
  };

  const onMapLoaded = (map: any, maps: any) => {
    map.setOptions({
      styles: mapStyle
    });

    if(defaultActivityFocus) {
      const activity = data.allActivity.edges.find(a => a.node.id === defaultActivityFocus);
      if(activity) {
        map.setCenter(activity.node.coordinates);
      }
    }

    // Load lines and generate polygons
    (async () => {
      const downloadURL = await firebase.storage().ref('hoogia/lines.json').getDownloadURL();
      fetch(downloadURL)
        .then(res => res.json())
        .then(lines => {
          // Find the stops of the lines that match a activities stop point
          const activityStopAreaGids = data.allActivity.edges.filter(edge => edge.node.published).map(edge => edge.node.stopareaGid);
          const infoWindow = new maps.InfoWindow();

          for(const direction of Object.keys(lines)) {
            const line = lines[direction];
            for(const stop of line.stops) {
              if(activityStopAreaGids.includes(stop.stopAreaGid)) {
                let iconUrl = '';
                if(stop.location === 'north') {
                  iconUrl = BusNorth;
                } else if(stop.location === 'east') {
                  iconUrl = BusEast;
                } else if(stop.location === 'south') {
                  iconUrl = BusSouth;
                } else if(stop.location === 'west') {
                  iconUrl = BusWest;
                }

                const marker = new maps.Marker({
                  position: {
                    lat: parseFloat(stop.lat),
                    lng: parseFloat(stop.lng),
                  },
                  map: map,
                  icon: {
                    url: iconUrl,
                    size: new maps.Size(24, 24),
                    scaledSize: new maps.Size(24, 24)
                  }
                });

                marker.addListener("click", () => {
                  infoWindow.close();
                  infoWindow.setContent(stop.name);
                  infoWindow.open(marker.getMap(), marker);
                });
              }
            }
          }
        });
    })();
  };

  useEffect(() => {
    setMarkers(
      filteredActivities().filter(edge => edge.node.published).map((edge, index) => {
        return (
          <span
            key={index}
            {...{
              lat: edge.node.coordinates.lat,
              lng: edge.node.coordinates.lng
            }}>
            {edge.node.id === selectedActivityId ? (
              <MarkerBlack
                className={styles.marker + ' ' + styles.markerActive}
                onClick={() => onMarkerClicked(edge.node)}></MarkerBlack>
            ) : (
              <Fragment>
                {edge.node.location[0] === 'north' &&
                  <MarkerNorth
                    className={styles.marker}
                    onClick={() => onMarkerClicked(edge.node)}></MarkerNorth>}
                {edge.node.location[0] === 'east' &&
                  <MarkerEast
                    className={styles.marker}
                    onClick={() => onMarkerClicked(edge.node)}></MarkerEast>}
                {edge.node.location[0] === 'south' &&
                  <MarkerSouth
                    className={styles.marker}
                    onClick={() => onMarkerClicked(edge.node)}></MarkerSouth>}
                {edge.node.location[0] === 'west' &&
                  <MarkerWest
                    className={styles.marker}
                    onClick={() => onMarkerClicked(edge.node)}></MarkerWest>}
              </Fragment>
            )}
          </span>
        );
      })
    );
  }, [filteredActivities, selectedActivity]);

  return (
    <div className={styles.mapContainer}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBb6Wqpv7bvF42qoBK8_Vu_q5dc6teaNdA', language: translation.lang }}
        defaultCenter={{ lat: 60.197830, lng: 19.948906 }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => onMapLoaded(map, maps)}
        defaultZoom={9}>
        {markers}
      </GoogleMapReact>

      <div className={styles.mapActionContainer}>
        <IconInput
          className={styles.inputField}
          placeholder={translation.translate('activities.search_placeholder')}
          value={query}
          onChange={event => setQuery(event.target.value)}
          icon={<div><SearchIcon style={{ width: '1rem', height: '1rem' }}></SearchIcon></div>}></IconInput>

        <div className={styles.badges}>
          <Badge.Filled
            onClick={openFilter}
            text={`${translation.translate('activities.filter')}${(filterResult || {}).numFilters > 0 ? ` (${filterResult.numFilters})` : ''}`}
            icon={<BiFilterAlt />}></Badge.Filled>
          <Badge.Outlined onClick={onPageToggle} text={translation.translate('activities.show_as_list')} icon={<BiMap />}></Badge.Outlined>
        </div>
      </div>

      {selectedActivity ?
        <SelectedActivity
          selectedActivity={selectedActivity}
          showSelectedActivity={showSelectedActivity}
          translation={translation}
          setFunnelOpen={setFunnelOpen}></SelectedActivity>
        : null}

      <BottomSheet open={filterState}>
        <ActivitiesFilter
          initialResult={filterResult}
          categories={data.allCategory}
          subCategories={data.allSubCategory}
          onClose={() => setFilterState(false)}
          onResult={onFilterResult}></ActivitiesFilter>
      </BottomSheet>
      <BottomSheet open={funnelOpen}>
        <SignupFunnel
          onClose={() => setFunnelOpen(false)}
          actionKey="favouriteActivity"></SignupFunnel>
      </BottomSheet>
    </div>
  );
}

export default function ActivitiesPage({ pageContext, data, location }: PageProps) {
  const [translation] = useState(new TranslationService(pageContext));
  const [page, setPage] = useState<SubPage>('list');
  const [defaultActivityFocus, setDefaultActivityFocus] = useState(null);

  useEffect(() => {
    const state = (location.state || {}) as any;
    if(state.fromSingleActivity) {
      setPage('map');
      setDefaultActivityFocus(state.activityId);
    } else {
      setDefaultActivityFocus(null);
    }
  }, []);

  return (
    <TranslationProvider value={translation}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{translation.translate('helmet.activities_title')}</title>
        <meta name="description" content={translation.translate('helmet.activities_description')} />
        <meta http-equiv="content-language" content={translation.lang} />
      </Helmet>
      <Header></Header>
      {page === 'list'
        ? <ListView onPageToggle={() => setPage('map')} translation={translation} data={data as ActivityQueryResult}></ListView>
        : <MapView onPageToggle={() => setPage('list')} translation={translation} data={data as ActivityQueryResult} defaultActivityFocus={defaultActivityFocus}></MapView>
      }
      <Navbar></Navbar>
    </TranslationProvider>
  );
}

export const query = graphql`
  query {
    allActivity {
      edges {
        node {
          id
          categoryId
          subCategoryId
          title {
            sv
            fi
            en
          }
          images {
            id
            name
            url
          }
          location
          free
          coordinates {
            lat
            lng
          }
          offers {
            amount
            percentage
            title {
              en
              fi
              sv
            }
          }
          published
          stopareaGid
          videoType
          embedUrl
          company {
            id
            slug
          }
          slug {
            sv
            fi
            en
          }
        }
      }
    }

    allCategory {
      edges {
        node {
          id
          name {
            sv
            fi
            en
          }
        }
      }
    }

    allSubCategory {
      edges {
        node {
          id
          parent {
            id
          }
          name {
            sv
            fi
            en
          }
        }
      }
    }
  }
`;