import { AlandLocationType } from '@/src/models/activity.model';
import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import * as styles from './activities.module.scss';
import Location from '@/src/components/location/location';
import useAuthStatus from '@/src/hooks/auth-status.hook';
import { getActivityService } from '@/src/services/activity.service';
import StarIcon from '@/assets/icons/star.inline.svg';
import StarFilledIcon from '@/assets/icons/star-filled.inline.svg';
import { Link } from 'gatsby';
import { CustomImage } from '@/src/components/image/image';

type ActivityMapItemProps = {
  open: boolean;
  heading: string;
  subheading: string;
  description: string;
  hasImage: boolean;
  imageUrl: string;
  locations: AlandLocationType[];
  href: string;
  activityId: string;
  onFailedFunnelTest: () => any;
};

export default function ActivityMapItem({ open, heading, subheading, description, imageUrl, hasImage, locations, href, activityId, onFailedFunnelTest }: ActivityMapItemProps) {
  const {isAuthenticated} = useAuthStatus();
  const [isFavourite, setIsFavourite] = useState(false);

  useEffect(() => {
    const activityService = getActivityService();
    activityService.init();
    const subscription = activityService.favouriteActivities$.subscribe(activities => {
      setIsFavourite(activities.includes(activityId))
    });

    return () => {
      subscription.unsubscribe();
    }
  }, []);

  const markAsFavourite = () => {
    if(isAuthenticated) {
      const activityService = getActivityService();
      activityService.setFavourite(activityId, !isFavourite);
    } else {
      onFailedFunnelTest();
    }
  };

  return (
    <CSSTransition
      in={open}
      classNames={{
        appear: styles.mapItem__appear,
        appearActive: styles.mapItem__appearActive,
        appearDone: styles.mapItem__appearDone,
        enter: styles.mapItem__enter,
        enterActive: styles.mapItem__enterActive,
        enterDone: styles.mapItem__enterDone,
        exit: styles.mapItem__exit,
        exitActive: styles.mapItem__exitActive,
        exitDone: styles.mapItem__exitDone
      }}
      timeout={200}
      unmountOnExit>
      <div className={styles.mapItem__container}>
        <Link to={href} className={styles.mapItem__inner}>
          {!hasImage ? <div className={styles.mapItem__image}></div> : null}
          {hasImage ? <CustomImage className={styles.mapItem__image} src={imageUrl} width={350} height={350}></CustomImage> : null}
          <p className={styles.mapItem__heading}>{heading}</p>
          <p className={styles.mapItem__subheading}>{subheading}</p>
          <p className={styles.mapItem__description}>{description}</p>
          <div className={styles.mapItem__locations}>
            {locations.map((location, index) => (
              <Location
                key={index}
                location={location} />
            ))}
          </div>
        </Link>
        <div className={styles.mapItem__favorite}>
          <button onClick={() => markAsFavourite()}>
            {isFavourite ? <StarFilledIcon></StarFilledIcon> : <StarIcon></StarIcon>}
          </button>
        </div>
      </div>
    </CSSTransition >
  );
};;;