import React, { forwardRef, Ref } from 'react';
import * as styles from './icon-input.module.scss';

type IconInputProps = {
  icon: JSX.Element;
  placeholder: string;
  className?: string;
  value?: string;
  onClick?: () => {};
  onChange?: (value: any) => any;
  onFocus?: () => any;
  onBlur?: () => any;
  parentRef?: Ref<any>;
};

function IconInput({ icon, placeholder, className = '', value, onClick, onChange, onFocus, onBlur, parentRef }: IconInputProps) {
  return (
    <div className={`${styles.wrapper} ${className}`}>
      <div className={styles.icon}>
        {icon}
      </div>
      <input 
        type="text"
        ref={parentRef}
        value={value}
        onClick={onClick}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}/>
    </div>
  );
}

export default forwardRef((props: IconInputProps, ref: Ref<any>) => (<IconInput {...props} parentRef={ref}></IconInput>))