import { useEffect, useState } from 'react';
import { ActivityModel, activitySearchParams } from '../models/activity.model';
import useDebounce from './debounce.hook';
import elasticlunr from 'elasticlunr';
import { TranslationService } from '../services/translation.service';
import { getActivityService } from '../services/activity.service';

type ActivityEdges = {
  edges: {
    node: ActivityModel;
  }[];
};

export default function useActivitySearch(activities: ActivityEdges, query: string, translation: TranslationService) {
  const [index, setIndex] = useState(null);
  const [filteredActivities, setFilteredActivities] = useState(activities.edges);
  const debouncedQuery = useDebounce(query, 250);

  useEffect(() => {
    const activitiesService = getActivityService();
    activitiesService.getActivitySearchIndex(translation.lang).then(i => {
      setIndex(elasticlunr.Index.load(i));
    });
  }, []);

  useEffect(() => {
    if(!query || !index) {
      setFilteredActivities(activities.edges);
      return;
    }

    // Search activities
    let results = [];
    if(index) {
      results = index.search(
        debouncedQuery,
        activitySearchParams
      );
    } else {
      results = [];
    }

    // Filter activities
    const resultIds = results.map(result => result.ref);
    const resultActivities: any[] = [];

    for(const resultId of resultIds) {
      let activity: any;
      for(const a of activities.edges) {
        if(a.node.id === resultId) {
          activity = a;
          break;
        }
      }
      if(activity) {
        resultActivities.push(activity);
      }
    }

    setFilteredActivities(resultActivities);

    // Order
  }, [debouncedQuery, index]);

  return filteredActivities;
}