import { LargeButton } from '@/src/components/button/button';
import { useTranslation } from '@/src/contexts/translation.context';
import { CategoryModel, SubCategoryModel } from '@/src/models/category.model';
import { EventService } from '@/src/services/events.service';
import React, { useEffect, useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import * as styles from './activities-filter.module.scss';

type ActivitiesFilterProps = {
  initialResult?: ActivitiesFilterResult;
  onResult?: (result: ActivitiesFilterResult) => any;
  onClose?: () => any;
  categories: {
    edges: {
      node: CategoryModel;
    }[];
  },
  subCategories: {
    edges: {
      node: SubCategoryModel;
    }[];
  };
};

export type ActivitiesFilterResult = {
  categoryId: string;
  subCategoryId: string;
  locations: {
    north: boolean;
    west: boolean;
    east: boolean;
    south: boolean;
  };
  numFilters: number;
};

export default function ActivitiesFilter({ categories, subCategories, onClose, onResult, initialResult }: ActivitiesFilterProps) {
  const translation = useTranslation();
  const [ category, setCategory ] = useState(initialResult ? initialResult.categoryId : 'ALL');
  const [ subCategory, setSubCategory ] = useState(initialResult ? initialResult.subCategoryId : 'ALL');
  const [ locations, setLocations ] = useState(initialResult ? initialResult.locations : { north: false, west: false, east: false, south: false });
  const [ filteredSubCategories, setFilteredSubCategories ] = useState([]);
  const [ initiate, setInitiate ] = useState(true);
  const [ firstFilterEvent, setFirstFilterEvent ] = useState(true);

  useEffect(() => {
    if(category && category !== 'ALL') {
      setFilteredSubCategories(subCategories.edges.filter(edge => edge.node.parent.id === category));
      if(!initiate) {
        setSubCategory('ALL');
      }
    } else {
      setFilteredSubCategories([]);
      setSubCategory('ALL');
    }
    setInitiate(false);
  }, [category]);

  useEffect(() => {
    if(onResult) {
      onResult({
        categoryId: category,
        subCategoryId: subCategory,
        locations: locations,
        numFilters: (
          Object.values(locations).filter(v => v).length +
          (category !== 'ALL' ? 1 : 0)
        )
      });
    }

    if(!firstFilterEvent) {
      const eventService = new EventService();
      eventService.logEvent(
        'filter',
        {
          categoryId: category,
          subCategoryId: subCategory,
          locations: locations,
        }
      );
    } else {
      setFirstFilterEvent(false);
    }
  }, [category, subCategory, locations]);

  const clearFilters = () => {
    setCategory('ALL');
    setSubCategory('ALL');
    setLocations({ north: false, west: false, east: false, south: false });
    setFilteredSubCategories([]);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.headingRow}>
          <h3 className={styles.heading}>{translation.translate('activities.filter')}</h3>
          <button onClick={onClose}>
            <IoIosClose size={30}></IoIosClose>
          </button>
        </div>

        <div className="app__form">
          <div className="form__group">
            <div className="form__field">
              <label htmlFor="category">{translation.translate('activities_filter.category')}:</label>
              <select name="category" className={styles.catFilter} value={category} onChange={event => setCategory(event.target.value)}>
                <option key="ALL" value="ALL">{translation.translate('activities_filter.all_categories')}</option>
                {categories.edges.map(edge => {
                  return (
                    <option key={edge.node.id} value={edge.node.id}>
                      {edge.node.name[translation.lang]}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          {category && category !== 'ALL' ?
            <div className="form__group">
              <div className="form__field">
                <label htmlFor="subCategory">{translation.translate('activities_filter.sub_category')}:</label>
                <select name="subCategory" className={styles.catFilter} value={subCategory} onChange={event => setSubCategory(event.target.value)}>
                  <option key="ALL" value="ALL">{translation.translate('activities_filter.all_sub_categories')}</option>
                  {filteredSubCategories.map(edge => {
                    return (
                      <option key={edge.node.id} value={edge.node.id}>
                        {edge.node.name[translation.lang]}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            : null}

          <div className="form__group">
            <div className="form__field">
              <label htmlFor="location">{translation.translate('activities_filter.location')}:</label>
              <div className={styles.locationContainer}>
                <button onClick={() => setLocations({ ...locations, north: !locations.north })} className={locations.north ? styles.buttonSelected : ''}>{translation.translate('activities_filter.locations.north')}</button>
                <button onClick={() => setLocations({ ...locations, south: !locations.south })} className={locations.south ? styles.buttonSelected : ''}>{translation.translate('activities_filter.locations.south')}</button>
                <button onClick={() => setLocations({ ...locations, west: !locations.west })} className={locations.west ? styles.buttonSelected : ''}>{translation.translate('activities_filter.locations.west')}</button>
                <button onClick={() => setLocations({ ...locations, east: !locations.east })} className={locations.east ? styles.buttonSelected : ''}>{translation.translate('activities_filter.locations.east')}</button>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.clearFilterContainer}>
          <button onClick={clearFilters}>{translation.translate('activities_filter.clear_filter')}</button>
        </div>

        <div className={styles.buttonContainer}>
          <LargeButton type="button" onClick={onClose}>
            <span>{translation.translate('activities_filter.filter_button')}</span>
          </LargeButton>
        </div>
      </div>
    </div>
  );
}