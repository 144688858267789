import { useCallback } from 'react';
import { ActivitiesFilterResult } from '../templates/activities/activities-filter/activities-filter';

let globalFilterResult: ActivitiesFilterResult = null;
export function setGlobalFilterResult(result: ActivitiesFilterResult) {
  globalFilterResult = result;
}

export function getGlobalFilterResult(): ActivitiesFilterResult {
  return globalFilterResult;
}

function findCommonElement(a: any[], b: any[]): boolean {
  for(let i=0;i<a.length;i++) {
    for(let j=0;j<b.length;j++) {
      if(a[i] === b[j]) {
        return true;
      }
    }
  }

  return false;
}

export default function useActivityFilter(filterResult: ActivitiesFilterResult, searchedActivities: any[]) {
  const filteredActivities = useCallback(() => {
    if(filterResult === null) {
      return searchedActivities;
    }

    const selectedLocations = Object.keys(filterResult.locations).filter(location => filterResult.locations[location]);

    return searchedActivities.filter(edge => {
      if(
        (edge.node.categoryId === filterResult.categoryId || filterResult.categoryId === 'ALL') &&
        (edge.node.subCategoryId === filterResult.subCategoryId || filterResult.categoryId === 'ALL' || filterResult.subCategoryId === 'ALL') &&
        (findCommonElement(edge.node.location, selectedLocations) || selectedLocations.length === 0)    
      ) {
        return true;
      } else {
        return false;
      }
    });
  }, [searchedActivities, filterResult]);

  return filteredActivities;
}