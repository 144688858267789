import Location from '@/src/components/location/location';
import { TranslationService } from '@/src/services/translation.service';
import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import * as styles from './activities.module.scss';
import useAuthStatus from '@/src/hooks/auth-status.hook';
import { getActivityService } from '@/src/services/activity.service';
import StarIcon from '@/assets/icons/star.inline.svg';
import StarFilledIcon from '@/assets/icons/star-filled.inline.svg';
import { CustomImage } from '@/src/components/image/image';
import PlaceholderUrl from '@/assets/images/placeholder.jpg';

type Location = 'north' | 'west' | 'east' | 'south';

type ActivityListItemProps = {
  heading: string;
  subheading: string;
  description: string;
  hasImage: boolean;
  imageUrl?: string;
  locations: Location[];
  translation: TranslationService;
  href: string;
  activityId: string;
  styling: string;
  onFailedFunnelTest: () => any;
};


export default function ActivityListItem({ translation, heading, subheading, description, locations, hasImage, imageUrl, href, activityId, styling, onFailedFunnelTest }: ActivityListItemProps) {
  const {isAuthenticated} = useAuthStatus();
  const [isFavourite, setIsFavourite] = useState(false);

  useEffect(() => {
    const activityService = getActivityService();
    activityService.init();
    const subscription = activityService.favouriteActivities$.subscribe(activities => setIsFavourite(activities.includes(activityId)));

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const markAsFavourite = () => {
    if(isAuthenticated) {
      const activityService = getActivityService();
      activityService.setFavourite(activityId, !isFavourite);
    } else {
      onFailedFunnelTest();
    }
  };

  return (
    <div className={styles.listItem__wrapper + ' ' + (styling === 'card' ? styles.listItem__wrapperCards : '')}>
      <Link to={href} className={styles.listItem}>
        <div className={styles.listItem__imageWrapper}>
          {!hasImage ? <img className={styles.listItem__image + ' ' + styles.listItem__imagePlaceholder} src={PlaceholderUrl}></img> : null}
          {hasImage ? <CustomImage className={styles.listItem__image} src={imageUrl} width={350} height={350}></CustomImage> : null}
        </div>
        <p className={styles.listItem__heading} data-mh>{heading}</p>
        <p className={styles.listItem__subheading}>{subheading}</p>
        <p className={styles.listItem__description}>{description}</p>
        <div className={styles.listItem__locations}>
          {locations.map((location, index) => (
            <Location
              key={index}
              location={location} />
          ))}
        </div>
      </Link>
      <div className={styles.listItem__favorite}>
        <button onClick={() => markAsFavourite()}>
          {isFavourite ? <StarFilledIcon></StarFilledIcon> : <StarIcon></StarIcon>}
        </button>
      </div>
    </div>
  );
}